<template>
  <div class="wrapper">
    <div class="header" :class="headerShow ? 'headerShow' : ''">
      <header>
        <div class="top">
          <!-- logo -->
          <div class="logo">数电通中小微企业服务生态平台</div>
          <!-- 菜单 -->
          <!-- <ul class="nav_list">
            <li class="nav_item" :class="{ 'nav-active': routeName == 'home' }">
              <router-link to="/">首页</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'enterpriseService' }"
            >
              <router-link to="/enterpriseService">基础服务</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'finance_1' }"
            >
              <router-link to="/finance_1">代账报税服务</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'finance_2' }"
            >
              <router-link to="/finance_2">金融服务</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'digitalService' }"
            >
              <router-link to="/digitalService">数字化服务</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'cases' }"
            >
              <router-link to="/cases">合作案例</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'partners' }"
            >
              <router-link to="/partners">合作伙伴</router-link>
            </li>
            <li
              class="nav_item"
              :class="{ 'nav-active': routeName == 'about' }"
            >
              <router-link to="/about">关于我们</router-link>
            </li>
          </ul> -->
          <!-- <div class="menu">
            <div class="menu-item">
              <router-link to="/">首页</router-link>
            </div>
            <div class="menu-item">
              <a href="#">基础服务</a>
              <ul class="sub-menu">
                <li class="sub-menu-item">
                  <router-link to="/enterpriseService">基础服务</router-link>
                </li>
                <li class="sub-menu-item">
                  <a href="#">子菜单2-2</a>
                </li>
                <li class="sub-menu-item">
                  <a href="#">子菜单2-3</a>
                </li>
              </ul>
            </div>
            <div class="menu-item">
              <a href="#">菜单3</a>
              <ul class="sub-menu">
                <li class="sub-menu-item">
                  <a href="#">子菜单3-1</a>
                </li>
                <li class="sub-menu-item">
                  <a href="#">子菜单3-2</a>
                </li>
                <li class="sub-menu-item">
                  <a href="#">子菜单3-3</a>
                </li>
              </ul>
            </div>
          </div> -->

          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="2">
              <template slot="title">代理记账服务</template>
              <el-menu-item index="/applyfor"
                >申请免费代理记账</el-menu-item
              >
              <el-menu-item index="/finance_1/detail_dzfw"
                >代理记账</el-menu-item
              >
              <el-menu-item index="/finance_1/detail_sdp">数电票</el-menu-item>
              <el-menu-item index="/certificateCollection"
                >凭证归集</el-menu-item
              >
              <el-menu-item index="/certificateCollection"
                >凭证归档</el-menu-item
              >

            </el-submenu>
            <el-submenu index="3">
              <template slot="title">金融服务</template>
              <el-menu-item index="/finance_2?#rzdk">融资贷款服务</el-menu-item>
              <el-menu-item index="/finance_2?#hzjg">合作机构</el-menu-item>
              <el-menu-item index="/finance_2?#kjbx">快捷服务</el-menu-item>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">税务服务</template>
              <el-menu-item index="/consult?#1">税务咨询</el-menu-item>
              <el-menu-item index="/consult?#2">税务风险评估</el-menu-item>
              <el-menu-item index="/consult?#3">税务审计</el-menu-item>
              <el-menu-item index="/consult?#4">税务培训</el-menu-item>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">知识产权服务</template>
              <el-menu-item index="/learningService?#1">高企认定</el-menu-item>
              <el-menu-item index="/learningService?#2">专精特新</el-menu-item>
              <el-menu-item index="/learningService?#3">商标申请</el-menu-item>
              <el-menu-item index="/learningService?#4">专利申请</el-menu-item>
            </el-submenu>
            <el-submenu index="6">
              <template slot="title">科技服务</template>
              <el-menu-item index="/scienceService?#1">网站搭建</el-menu-item>
              <el-menu-item index="/scienceService?#2">微网站建设</el-menu-item>
              <el-menu-item index="/scienceService?#3">小程序建设</el-menu-item>
              <el-menu-item index="/scienceService?#4">APP建设</el-menu-item>
            </el-submenu>
            <el-submenu index="7">
              <template slot="title">扩容提质服务</template>
              <el-menu-item index="/digitalService?#zffw"
                >支付服务</el-menu-item
              >
              <el-menu-item index="/digitalService?#qyjy"
                >企业经营</el-menu-item
              >
              <el-menu-item index="/digitalService?#cjcp"
                >场景产品</el-menu-item
              >
              <el-menu-item index="/digitalService?#hlht"
                >互联互通</el-menu-item
              >
            </el-submenu>
            <el-submenu index="8">
              <template slot="title">咨询培训服务</template>
              <el-menu-item index="/series?#1">财务管理</el-menu-item>
              <el-menu-item index="/series?#2">股权讲堂</el-menu-item>
              <el-menu-item index="/live">财税直播间</el-menu-item>
              <el-menu-item index="/series?#3">上市公司管理</el-menu-item>
            </el-submenu>
            <el-submenu index="9">
              <template slot="title">法律服务</template>
              <el-menu-item index="/lawService?#1">法律咨询</el-menu-item>
              <el-menu-item index="/lawService?#2">法律顾问</el-menu-item>
              <el-menu-item index="/lawService?#3">投资合伙</el-menu-item>
              <el-menu-item index="/lawService?#4">企业法律</el-menu-item>
            </el-submenu>
            <el-submenu index="10">
              <template slot="title">支付服务</template>
              <el-menu-item index="/digitalService/detail_pos"
                >支付产品-POS</el-menu-item
              >
              <el-menu-item index="/digitalService/detail_jhm"
                >支付产品-聚合码</el-menu-item
              >
              <el-menu-item index="/digitalService/detail_sfk"
                >支付产品-批量代收付</el-menu-item
              >
            </el-submenu>
            <el-menu-item index="/about">关于我们</el-menu-item>
            <el-menu-item index="/satisfaction">满意度评价</el-menu-item>
          </el-menu>
        </div>
        <!-- 登录 -->
        <div class="top_right">
          <div class="login" @click="login">立即登录</div>
        </div>
      </header>
    </div>
    <div class="contact_box">
      <div class="contact">
        <a
          href="https://kefu11.cckefucloud.com/vclient/chat/?websiteid=yewjrzkm098g15xje144qp3b6ey7v24d&wc=84924d98"
          target="_blank"
        >
          <div class="contact_icon">
            <img src="../assets/img/home/kefu.png" alt="" />
          </div>
          <span>在线客服</span>
        </a>
      </div>
      <div class="gotop" @click="gotop">
        <img src="../assets/img/home/top.png" alt="" />
      </div>
    </div>
    <!-- 内容 -->
    <router-view></router-view>
    <!-- 公共底部 -->
    <div class="bottom">
      <div class="phone">
        <div class="phone_content">
          <div class="custService">
            <img src="../assets/img/cust.png" alt="" />
          </div>
          <span>咨询热线 / 400 666 3349</span>
        </div>
      </div>
      <div class="middle">
        <!-- <div class="left">
          <div class="b_login">
            <img src="../assets/img/logo.png" alt="" />
          </div>
        </div> -->
        <div class="center">
          <ul class="nav_list_b">
            <li class="nav_item_b">
              <span>首页</span>
              <ul>
                <li>热门服务</li>
                <li>最新活动</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>代理记账服务</span>
              <ul>
                <li>申请免费代理记账</li>
                <li>代账报税</li>
                <li>数电票</li>
                <li>凭证归集</li>
                <li>凭证归档</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>金融服务</span>
              <ul>
                <li>融资贷款服务</li>
                <li>合作机构</li>
                <li>快捷服务</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>税务服务</span>
              <ul>
                <li>税务咨询</li>
                <li>税务风险评估</li>
                <li>税务审计</li>
                <li>税务培训</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>知识产权服务</span>
              <ul>
                <li>高企认定</li>
                <li>专精特新</li>
                <li>商标申请</li>
                <li>专利申请</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>科技服务</span>
              <ul>
                <li>网站搭建</li>
                <li>微网站建设</li>
                <li>小程序建设</li>
                <li>APP建设</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>扩容提质服务</span>
              <ul>
                <li>支付服务</li>
                <li>企业经营</li>
                <li>场景产品</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>咨询培训服务</span>
              <ul>
                <li>财务管理</li>
                <li>股权讲堂</li>
                <li>财税直播间</li>
                <li>上市公司管理</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>法律服务</span>
              <ul>
                <li>法律咨询</li>
                <li>法律顾问</li>
                <li>投资合伙</li>
                <li>企业法律</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>支付服务</span>
              <ul>
                <li>支付产品-POS</li>
                <li>支付产品-聚合码</li>
                <li>支付产品-批量代收付</li>
              </ul>
            </li>
            <li class="nav_item_b">
              <span>关于我们</span>
              <!-- <ul>
                <li>支付产品-POS</li>
                <li>支付产品-聚合码</li>
                <li>支付产品-批量代收付</li>
              </ul> -->
            </li>
            <li class="nav_item_b">
              <span>满意度评价</span>
              <!-- <ul>
                <li>支付产品-POS</li>
                <li>支付产品-聚合码</li>
                <li>支付产品-批量代收付</li>
              </ul> -->
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="code">
            <div class="code_img">
              <img src="../assets/img/code.png" alt="" />
            </div>
            <div class="code_title">
              <span>官方微信公众号</span>
              <span>微信扫码关注</span>
            </div>
          </div>
        </div>
      </div>
      <div class="down">
        <div class="down_top">
          <span>©2007-现在 金卡易联版权所有</span>
          <div class="icon icon1">
            <img src="../assets/img/ga1.png" alt="" />
          </div>
          <div class="icon icon2">
            <a
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=14019202000131"
              target="_blank"
            >
              <img src="../assets/img/gongan_ico.png" alt="" />
              <span>晋公网安备14019202000131号</span>
            </a>
          </div>
        </div>
        <div class="down_bottom">
          <a
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noopener noreferrer"
          >
            增值电信业务经营许可证：晋ICP备10201197号-1
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "./home/index.vue";
export default {
  components: {
    Home,
  },
  props: {},
  data() {
    return {
      routeName: "",
      headerShow: false,
      activeIndex: "1",
    };
  },
  watch: {},
  computed: {},
  methods: {
    gotop() {
      document.documentElement.scrollTop = 0;
    },
    login() {
      window.location.href = process.env.VUE_APP_LOGIN_URL;
    },
    toIndex() {
      return this.$route.path.split("/")[1];
    },

    // handleSelect(index) {
    //   this.$router.push("/" + index);
    // },

    handleSelect(key, keyPath) {
      console.log(key);
      console.log(keyPath);
      this.$router.push({
        path: key,
      });
    },

    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // console.log("header 滚动距离 ", scrollTop);
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;

      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;

      //滚动条到底部的条件
      if (scrollTop + windowHeight == scrollHeight) {
        //你想做的事情
        // console.log("header  你已经到底部了");
      }
      if (scrollStep < 0) {
        // console.log("header 滚动条向上滚动了！");
      } else {
        // console.log("header  滚动条向下滚动了！");
      }
      if (scrollTop > 0) {
        this.headerShow = true;
      } else {
        this.headerShow = false;
      }

      // 判断是否到了最顶部
      if (scrollTop <= 0) {
        // console.log("header 到了最顶部");
      }
    },
  },
  created() {
    this.routeName = this.$route.name;
  },
  mounted() {
    // 监听页面滚动事件
    window.addEventListener("scroll", this.scrolling);
  },
  updated() {
    this.routeName = this.$route.name;
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>
<style scoped>
.wrapper {
  width: 100%;
}

.header {
  /* position: fixed;
  top: 0;
  left: 0;
  z-index: 999; */
  width: 100%;
  min-width: 1600px;
  height: 60px;
  color: #fff;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: #fff;
}
.header .nav_item a {
  color: #fff;
}

/* .header:hover {
  color: #055afe;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */

.header:hover .nav_item a {
  color: #999;
}

.headerShow {
  color: #055afe;
  background: #fff;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.headerShow .nav_item a {
  color: #999;
}

header {
  /* width: 1200px; */
  /* width: 1700px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  justify-content: center;
}

.logo {
  width: 280px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  font-size: 20px;
  font-weight: 600;
  color: #055afe;
}

.logo img {
  display: block;
  width: 100%;
  height: 100%;
}

.top {
  display: flex;
  align-items: center;
}

.nav_list {
  display: flex;
  align-items: center;
}

.nav_item {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  color: #000;
  padding: 0 20px;
}

.nav_item a {
  display: block;
  width: 100%;
  height: 100%;
}

.nav-active {
  height: 58px;
  border-bottom: 2px solid #055afe;
}

.nav_item:hover {
  font-weight: 600;
  color: #333333;
}

.login {
  width: 100px;
  font-size: 14px;
  text-align: center;
  padding: 5px 20px;
  border-radius: 25px;
  color: #fff;
  background: #055afe;
  cursor: pointer;
}

.bottom {
  width: 100%;
  padding-top: 80px;
}

.phone {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #055afe;
  text-align: center;
}

.phone span {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.phone_content {
  position: relative;
  width: 240px;
  margin-left: 30px;
  margin: 0 auto;
}

.custService {
  position: absolute;
  top: -27px;
  left: -100px;
  width: 90px;
  height: 90px;
}

.custService img {
  display: block;
  width: 100%;
  height: 100%;
}

header >>> .el-submenu__title {
  padding: 0 10px;
}

/* 中间 */
.middle {
  /* width: 1200px; */
  min-width: 1600px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* justify-content: space-around; */
  margin: 0 auto;
  border-bottom: 1px solid rgba(166, 166, 166, 0.4);
}

.left {
  display: flex;
  align-items: center;
}

.b_login {
  width: 180px;
  height: 16px;
}

.b_login img {
  display: block;
  width: 100%;
  height: 100%;
}

.center {
  padding: 30px 0;
}

.nav_list_b {
  display: flex;
  font-size: 14px;
}

.nav_item_b {
  padding: 0 15px;
  cursor: pointer;
}

.nav_item_b span {
  display: block;
  width: 100%;
  text-align: center;
}

.nav_item_b ul {
  margin-top: 20px;
}

.nav_item_b li {
  text-align: center;
  font-size: 14px;
  color: #a6a6a6;
  margin-top: 10px;
}

/* 二维码 */
.code {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code_title {
  font-size: 16px;
  color: #a6a6a6;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.code_title span {
  display: block;
  font-size: 14px;
  text-align: center;
}

/* 备案 */
.down {
  width: 1200px;
  padding: 20px;
  margin: 0 auto;
}

.down_top {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #a6a6a6;
}

.icon {
  padding: 0 10px;
}

.icon1 {
  margin-left: 10px;
  border-left: 1px solid rgba(166, 166, 166, 0.4);
  border-right: 1px solid rgba(166, 166, 166, 0.4);
}

.icon img {
  display: block;
  width: 16px;
  height: 16px;
}

.icon2 a {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #a6a6a6;
}

.icon2 a span {
  display: block;
}

.down_bottom {
  text-align: center;
  font-size: 14px;
  color: #a6a6a6;
  margin-top: 5px;
}

.down_bottom a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #a6a6a6;
}

.contact_box {
  position: fixed;
  top: 50%;
  right: 20px;
  z-index: 999;
  width: 50px;
  height: 130px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
  border-radius: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background: #fff;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 14px; */
}

.contact a {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 14px;
}

.contact_icon img {
  width: 33px;
  height: 33px;
}

.contact span {
  display: block;
}

.gotop {
  width: 100%;
  height: 30px;
}

.gotop img {
  display: block;
  width: 15px;
  height: 17px;
  margin: 0 auto;
}

.menu {
  display: flex;
}

.menu-item {
  position: relative;
}

.menu-item a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.sub-menu {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;
}

.sub-menu-item {
  position: relative;
}

.sub-menu-item a {
  display: block;
  padding: 10px;
  color: #333;
  text-decoration: none;
}

.menu-item:hover .sub-menu {
  display: block;
}

.el-menu {
  width: 1250px;
}
.el-menu.el-menu--horizontal {
  width: 1250px;
}

/* @media (max-width: 1600px) {
  .logo {
    display: none;
  }
} */
</style>
